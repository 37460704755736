// src/App.js
import React from 'react';
import './App.css';

const App = () => {
  return (
    <div className="app-container">
      <iframe src="https://smaller-colleagues-475758.framer.app" title="" />
    </div>
  );
};

export default App;

